/* eslint-disable react/jsx-pascal-case */
import { useMemo, useState } from 'react';
import { InsuranceCompany } from '../components/types';
import { Link } from 'react-router-dom';
import { cn } from 'util/index';
import { InsuranceProductsData, InsuranceTypeTab } from '..';

const ALERTCONTENT = [
  '차 보험 광고(차 보험 가입하기)는 카사요(주)가 제휴 보험회사의 다이렉트 자동차 보험상품을 광고하는 서비스입니다. 본 광고 서비스를 통하여 카사요(주)는 상품 판매 및 중개에 관여하지 않습니다.',
  '해당 보험사는 관련 상품에 대해 충분히 설명할 의무가 있으며, 가입자는 가입에 앞서 이에 대한 충분한 설명을 받으시길 바랍니다.',
  '보험계약 체결 전 상품설명서 및 약관을 읽어보시길 바랍니다.',
  '보험계약자가 기존에 체결했던 보험계약을 해지하고, 다른 보험계약을 체결할 때는 보험인수가 거절되거나 보험료가 인상되거나 보장 내용이 달라질 수 있습니다.',
  '지급 한도, 면책 사항 등에 따라 보험금 지급이 제한될 수 있습니다.',
  '이 보험계약은 예금자보호법에 따라 해약환급금(또는 만기 시 보험금)에 기타 지급금을 합한 금액이 1인당 "5천만 원까지"(본 보험회사의 여타 보험상품과 합산) 보호됩니다.\n이와 별도로 본 보험회사 보호 상품의 사고 보험금을 합산한 금액이 1인당 \"5천만 원까지\" 보호됩니다.',
  '한 보험사의 차 보험 가입 광고로 받을 수 있는 혜택은 최대 30,000원입니다.',
  '차 보험 가입 광고의 상세 내용은 각 보험사 페이지에서 확인할 수 있습니다.',
  '차 보험 가입 이후 포인트 지급 관련 문의는 각 보험사에 문의해 주세요.',
  '본 광고는 광고 심의 기준을 준수하였으며, 유효기간은 각 보험사 심의일로부터 1년입니다.',
];

function InsuranceCard({
  insuranceCompany,
  option,
}: {
  insuranceCompany: InsuranceCompany;
  option?: {
    isPhone: boolean;
  };
}) {
  if (option?.isPhone) {
    return (
      <div key={insuranceCompany.id} rel='noopener noreferrer'>
        <li
          className={cn(
            'rounded-[30px] px-[30px] py-[36px] max-w-[386px] h-[386px] relative flex flex-col justify-between gap-[24px] pb-[100px]'
          )}
          style={{ backgroundColor: insuranceCompany.theme_bgcolor }}
        >
          <div className='flex flex-col gap-[24px]'>
            <div className='flex flex-col gap-[14px]'>
              <div className='text-[16px] font-medium leading-[16px]'>
                {insuranceCompany.name}
              </div>
              <div className='text-[22px] font-semibold leading-[22px]'>
                {insuranceCompany.direct.title}
              </div>
            </div>

            <div
              className={`border-l-[2px] pl-[14px] text-[15px] leading-[24px] font-[400]`}
              style={{
                borderColor: insuranceCompany.theme_color,
              }}
              dangerouslySetInnerHTML={{
                __html: insuranceCompany.direct.description,
              }}
            ></div>
          </div>

          <a
            href={`tel:${insuranceCompany.direct.signup_tel}`}
            className='bg-white rounded-full mb-5 py-[19px] flex items-center justify-center gap-1.5 cursor-pointer hover:bg-gray-50'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
            >
              <path
                d='M16.479 13.7475C16.479 14.0175 16.419 14.295 16.2915 14.565C16.164 14.835 15.999 15.09 15.7815 15.33C15.414 15.735 15.009 16.0275 14.5515 16.215C14.1015 16.4025 13.614 16.5 13.089 16.5C12.324 16.5 11.5065 16.32 10.644 15.9525C9.78146 15.585 8.91896 15.09 8.06396 14.4675C7.20146 13.8375 6.38396 13.14 5.60396 12.3675C4.83146 11.5875 4.13396 10.77 3.51146 9.915C2.89646 9.06 2.40146 8.205 2.04146 7.3575C1.68146 6.5025 1.50146 5.685 1.50146 4.905C1.50146 4.395 1.59146 3.9075 1.77146 3.4575C1.95146 3 2.23646 2.58 2.63396 2.205C3.11396 1.7325 3.63896 1.5 4.19396 1.5C4.40396 1.5 4.61397 1.545 4.80147 1.635C4.99646 1.725 5.16896 1.86 5.30397 2.055L7.04397 4.5075C7.17897 4.695 7.27646 4.8675 7.34396 5.0325C7.41146 5.19 7.44897 5.3475 7.44897 5.49C7.44897 5.67 7.39646 5.85 7.29146 6.0225C7.19396 6.195 7.05146 6.375 6.87146 6.555L6.30146 7.1475C6.21896 7.23 6.18146 7.3275 6.18146 7.4475C6.18146 7.5075 6.18897 7.56 6.20397 7.62C6.22647 7.68 6.24897 7.725 6.26397 7.77C6.39897 8.0175 6.63146 8.34 6.96146 8.73C7.29896 9.12 7.65896 9.5175 8.04896 9.915C8.45396 10.3125 8.84396 10.68 9.24146 11.0175C9.63146 11.3475 9.95396 11.5725 10.209 11.7075C10.2465 11.7225 10.2915 11.745 10.344 11.7675C10.404 11.79 10.464 11.7975 10.5315 11.7975C10.659 11.7975 10.7565 11.7525 10.839 11.67L11.409 11.1075C11.5965 10.92 11.7765 10.7775 11.949 10.6875C12.1215 10.5825 12.294 10.53 12.4815 10.53C12.624 10.53 12.774 10.56 12.939 10.6275C13.104 10.695 13.2765 10.7925 13.464 10.92L15.9465 12.6825C16.1415 12.8175 16.2765 12.975 16.359 13.1625C16.434 13.35 16.479 13.5375 16.479 13.7475Z'
                fill='#222222'
                stroke='#222222'
                strokeWidth='1.125'
                strokeMiterlimit='10'
              />
            </svg>
            <div className='text-[#222] text-center text-[18px] font-medium leading-[100%] tracking-[-0.36px]'>
              {insuranceCompany.direct.signup_tel}
            </div>
          </a>

          <div className='h-[28px] w-[50px] absolute right-0 top-[10%] p-[8px_15px] gap-[10px] rounded-[20px_0px_0px_20px] bg-[#22222280] text-white font-[700] text-[14px] leading-[14px]'>
            AD
          </div>
          <div className='absolute bottom-0 left-0 rounded-bl-[30px] rounded-br-[30px] bg-[#F8F9FA] p-[30px] w-full justify-between flex items-center max-h-[100px]'>
            <div className='text-[#555555] text-[13px] leading-[19.5px] font-[400] mr-10'>
              {insuranceCompany.direct.certification}
            </div>
            <img
              className='w-[60px] h-[60px]'
              alt={insuranceCompany.name}
              src={insuranceCompany.logo_url}
            />
          </div>
        </li>
      </div>
    );
  }

  return (
    <Link
      key={insuranceCompany.id}
      to={
        insuranceCompany.direct.signup_url_web ??
        insuranceCompany.direct.signup_url_mobile ??
        ''
      }
      target='_blank'
      rel='noopener noreferrer'
    >
      <li
        className={cn(
          'cursor-pointer rounded-[30px] px-[30px] py-[36px] max-w-[386px] min-h-[304px] relative flex flex-col gap-[24px]'
        )}
        style={{ backgroundColor: insuranceCompany.theme_bgcolor }}
      >
        <div className='flex flex-col gap-[14px]'>
          <div className='text-[16px] font-medium leading-[16px]'>
            {insuranceCompany.name}
          </div>
          <div className='text-[22px] font-semibold leading-[22px]'>
            {insuranceCompany.direct.title}
          </div>
        </div>

        <div
          className={`border-l-[2px] pl-[14px] text-[15px] leading-[24px] font-[400]`}
          style={{
            borderColor: insuranceCompany.theme_color,
          }}
          dangerouslySetInnerHTML={{
            __html: insuranceCompany.direct.description,
          }}
        ></div>

        <div className='h-[28px] w-[50px] absolute right-0 top-[10%] p-[8px_15px] gap-[10px] rounded-[20px_0px_0px_20px] bg-[#22222280] text-white font-[700] text-[14px] leading-[14px]'>
          AD
        </div>
        <div className='absolute bottom-0 left-0 rounded-bl-[30px] rounded-br-[30px] bg-[#F8F9FA] p-[30px] w-full justify-between flex items-center max-h-[100px]'>
          <div className='text-[#555555] text-[13px] leading-[19.5px] font-[400] mr-10'>
            {insuranceCompany.direct.certification}
          </div>
          <img
            className='w-[60px] h-[60px]'
            alt={insuranceCompany.name}
            src={insuranceCompany.logo_url}
          />
        </div>
      </li>
    </Link>
  );
}

function 함께보기({
  insuranceProducts_links,
  insuranceProducts_phone,
}: {
  insuranceProducts_links: InsuranceCompany[];
  insuranceProducts_phone: InsuranceCompany[];
}) {
  return (
    <div className='w-[1200px] h-full mx-auto items-start flex gap-[26px] mb-[50px]'>
      {/* 가입 링크가 있는 상품 영역 */}
      <div className='flex-auto'>
        <div className='pb-4 mb-[8px]'>
          <span className='text-[#222] font-Pretendard text-[20px] font-bold leading-[150%] tracking-[-0.5px]'>
            온라인 가입
          </span>
        </div>

        <ul
          className={cn(
            'grid grid-cols-2 gap-4',
            insuranceProducts_phone.length === 0 ? 'grid-cols-3' : 'grid-cols-2'
          )}
        >
          {insuranceProducts_links.map((product) => (
            <InsuranceCard insuranceCompany={product} />
          ))}
        </ul>
      </div>

      {/* 가입 전화번호가 있는 상품 영역 */}
      {insuranceProducts_phone.length > 0 && (
        <div className='w-[386px] flex-none'>
          <div className='pb-4 mb-[8px]'>
            <span className='bg-gradient-to-r from-[#00BCD8] to-[#00A287] bg-clip-text text-transparent text-[20px] font-bold leading-[150%] tracking-[-0.5px]'>
              전화 가입
            </span>
          </div>

          <ul className='space-y-5'>
            {insuranceProducts_phone.map((product) => {
              return (
                <InsuranceCard
                  insuranceCompany={product}
                  option={{ isPhone: true }}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
function HorizonOnline({
  insuranceProducts_links,
}: {
  insuranceProducts_links: InsuranceCompany[];
}) {
  return (
    <div className='w-[1200px] h-full mx-auto items-start flex flex-col gap-[24px] mb-[50px]'>
      <div className='w-full'>
        <span className='text-[#222] font-Pretendard text-[20px] font-bold leading-[150%] tracking-[-0.5px]'>
          온라인 가입
        </span>
      </div>
      <div className='w-full flex-1'>
        <ul className='grid grid-cols-3 gap-[21px]'>
          {insuranceProducts_links.map((product) => (
            <InsuranceCard insuranceCompany={product} />
          ))}
        </ul>
      </div>
    </div>
  );
}
function HorizonTel({
  insuranceProducts_phone,
}: {
  insuranceProducts_phone: InsuranceCompany[];
}) {
  if (insuranceProducts_phone.length === 0) {
    return null;
  }

  return (
    <div className='w-[1200px] h-full mx-auto items-start flex flex-col gap-[24px] mb-[50px]'>
      <div className='w-full'>
        <span className='bg-gradient-to-r from-[#00BCD8] to-[#00A287] bg-clip-text text-transparent text-[20px] font-bold leading-[150%] tracking-[-0.5px]'>
          전화로 만나보는 간편한 가입
        </span>
      </div>
      <div className='w-full flex-1'>
        <ul className='grid grid-cols-3 gap-[21px]'>
          {insuranceProducts_phone.map((product) => (
            <InsuranceCard
              insuranceCompany={product}
              option={{ isPhone: true }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

function Notice() {
  return (
    <div className='w-[1200px] mx-auto p-6 items-start flex flex-col gap-4 mb-[100px] rounded-[20px] bg-[#F8F9FA]'>
      <div className='text-[#222] font-Pretendard text-[15px] font-medium leading-[150%] tracking-[-0.3px]'>
        보험계약 시 알아두실 사항 확인해 주세요
      </div>
      <div className='w-full h-[1px] bg-[#EEE]'></div>
      <ul className='mt-1 list-decimal pl-4 space-y-1.5'>
        {ALERTCONTENT.map((el) => {
          return (
            <li className='text-[#555] font-Pretendard text-[14px] font-normal leading-[150%] tracking-[-0.35px] whitespace-pre-line'>
              {el}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

type Mode = '함께보기' | '온라인가입순' | '전화가입순';
function ModeController({
  mode,
  setMode,
  insuranceTypeTab,
  setInsuranceTypeTab,
}: {
  mode: Mode;
  setMode: (mode: Mode) => void;
  insuranceTypeTab: InsuranceTypeTab;
  setInsuranceTypeTab: (insuranceTypeTab: InsuranceTypeTab) => void;
}) {
  return (
    <div className='w-[1200px] h-full mx-auto items-center justify-between flex mb-[30px] mt-[-20px] border-b border-[#DDD]'>
      <div className='flex'>
        <div
          onClick={() => {
            setInsuranceTypeTab('personal');
          }}
          className={cn(
            'relative w-[180px] h-[56px] flex items-center justify-center cursor-pointer',
            insuranceTypeTab === 'personal'
              ? 'text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.5px]'
              : 'text-[#999] text-[20px] font-medium leading-[20px] tracking-[-0.5px]'
          )}
        >
          <div>개인용</div>
          <div
            className={cn(
              'absolute bottom-0 left-0 w-full h-[2px] bg-[#000]',
              insuranceTypeTab !== 'personal' && 'hidden'
            )}
          ></div>
        </div>
        <div
          onClick={() => {
            setInsuranceTypeTab('business');
          }}
          className={cn(
            'relative w-[180px] h-[56px] flex items-center justify-center cursor-pointer',
            insuranceTypeTab === 'business'
              ? 'text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.5px]'
              : 'text-[#999] text-[20px] font-medium leading-[20px] tracking-[-0.5px]'
          )}
        >
          <div>법인(영업)용</div>
          <div
            className={cn(
              'absolute bottom-0 left-0 w-full h-[2px] bg-[#000]',
              insuranceTypeTab !== 'business' && 'hidden'
            )}
          ></div>
        </div>
      </div>

      <div
        className={cn(
          'flex gap-[6px] items-center',
          insuranceTypeTab === 'business' && 'hidden'
        )}
      >
        <button
          onClick={() => {
            setMode('함께보기');
          }}
          className={cn(
            'px-2.5 py-2 rounded-[8px]',
            mode === '함께보기'
              ? 'text-[#000] font-Pretendard text-[15px] font-semibold leading-[150%] tracking-[-0.375px]'
              : 'bg-[#FFFFFF] text-[#999] font-Pretendard text-[15px] font-medium leading-[150%] tracking-[-0.375px]'
          )}
        >
          함께 보기
        </button>
        <div className='w-[1px] bg-[#EEE] h-[12px]'></div>
        <button
          onClick={() => {
            setMode('온라인가입순');
          }}
          className={cn(
            'px-2.5 py-2 rounded-[8px]',
            mode === '온라인가입순'
              ? 'text-[#000] font-Pretendard text-[15px] font-semibold leading-[150%] tracking-[-0.375px]'
              : 'bg-[#FFFFFF] text-[#999] font-Pretendard text-[15px] font-medium leading-[150%] tracking-[-0.375px]'
          )}
        >
          온라인 가입순
        </button>

        <div className='w-[1px] bg-[#EEE] h-[12px]'></div>
        <button
          onClick={() => {
            setMode('전화가입순');
          }}
          className={cn(
            'px-2.5 py-2 rounded-[8px]',
            mode === '전화가입순'
              ? 'text-[#000] font-Pretendard text-[15px] font-semibold leading-[150%] tracking-[-0.375px]'
              : 'bg-[#FFFFFF] text-[#999] font-Pretendard text-[15px] font-medium leading-[150%] tracking-[-0.375px]'
          )}
        >
          전화 가입순
        </button>
      </div>
    </div>
  );
}

export default function InsuranceProductList({
  insuranceProducts,
  insuranceTypeTab,
  setInsuranceTypeTab,
}: {
  insuranceProducts: InsuranceProductsData;
  insuranceTypeTab: InsuranceTypeTab;
  setInsuranceTypeTab: (insuranceTypeTab: InsuranceTypeTab) => void;
}) {
  const [mode, setMode] = useState<Mode>('함께보기');

  /** 온라인 판매 목록 */
  const InsuranceProducts_links: InsuranceProductsData = useMemo(() => {
    const data: InsuranceProductsData = {
      default: [],
      corporation: [],
      business: [],
    };

    insuranceProducts.default.forEach((el) => {
      if (el.type === 'online') {
        data.default.push(el);
      }
    });

    // 법인(영업용)의 경우 웹용 링크가 있을 때에만 노출합니다.
    insuranceProducts.corporation.forEach((el) => {
      if (el.type === 'online' && el.direct.signup_url_web) {
        data.corporation.push(el);
      }
    });

    // 법인(영업용)의 경우 웹용 링크가 있을 때에만 노출합니다.
    insuranceProducts.business.forEach((el) => {
      if (el.type === 'online' && el.direct.signup_url_web) {
        data.business.push(el);
      }
    });
    return data;
  }, [insuranceProducts]);

  /** 텔레마케팅 판매 목록 */
  const InsuranceProducts_phone: InsuranceProductsData = useMemo(() => {
    const data: InsuranceProductsData = {
      default: [],
      corporation: [],
      business: [],
    };

    insuranceProducts.default.forEach((el) => {
      if (el.type === 'tm') {
        data.default.push(el);
      }
    });

    insuranceProducts.corporation.forEach((el) => {
      if (el.type === 'tm') {
        data.corporation.push(el);
      }
    });

    insuranceProducts.business.forEach((el) => {
      if (el.type === 'tm') {
        data.business.push(el);
      }
    });
    return data;
  }, [insuranceProducts]);

  return (
    <>
      <ModeController
        mode={mode}
        setMode={setMode}
        insuranceTypeTab={insuranceTypeTab}
        setInsuranceTypeTab={setInsuranceTypeTab}
      />

      {insuranceTypeTab === 'personal' && (
        <>
          {mode === '함께보기' && (
            <함께보기
              insuranceProducts_links={InsuranceProducts_links.default}
              insuranceProducts_phone={InsuranceProducts_phone.default}
            />
          )}

          {mode === '온라인가입순' && (
            <>
              <HorizonOnline
                insuranceProducts_links={InsuranceProducts_links.default}
              />
              <HorizonTel
                insuranceProducts_phone={InsuranceProducts_phone.default}
              />
            </>
          )}

          {mode === '전화가입순' && (
            <>
              <HorizonTel
                insuranceProducts_phone={InsuranceProducts_phone.default}
              />
              <HorizonOnline
                insuranceProducts_links={InsuranceProducts_links.default}
              />
            </>
          )}
        </>
      )}

      {insuranceTypeTab === 'business' && (
        <>
          <div className='bg-[#E8F8FF] rounded-[10px] p-4 flex items-center gap-3 mb-[30px] w-[1200px] justify-start'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
            >
              <g clip-path='url(#clip0_2429_12941)'>
                <path
                  d='M14.5491 9.75463V1.80787C14.5491 0.810185 13.7389 0 12.7412 0H1.80836C0.810673 0 0.000488281 0.810185 0.000488281 1.80787V18.1921C0.000488281 19.1898 0.810673 20 1.80836 20H18.1926C19.1903 20 20.0005 19.1898 20.0005 18.1921V12.3634C20.0005 11.3657 19.1903 10.5556 18.1926 10.5556H15.35C14.9079 10.5556 14.5491 10.1968 14.5491 9.75463Z'
                  fill='#61B3FF'
                />
                <path
                  d='M10.0652 6.70739H5.16473C4.52584 6.70739 4.00732 6.18887 4.00732 5.54999C4.00732 4.9111 4.52584 4.39258 5.16473 4.39258H10.0652C10.7041 4.39258 11.2226 4.9111 11.2226 5.54999C11.2226 6.18887 10.7041 6.70739 10.0652 6.70739Z'
                  fill='#EBF9FF'
                />
                <path
                  d='M10.7982 19.9996H4.43018V12.7913C4.43018 11.032 5.8561 9.6084 7.61305 9.6084C9.37231 9.6084 10.7959 11.0343 10.7959 12.7913V19.9996H10.7982Z'
                  fill='#EBF9FF'
                />
              </g>
              <defs>
                <clipPath id='clip0_2429_12941'>
                  <rect
                    width='20'
                    height='20'
                    fill='white'
                    transform='translate(0.000488281)'
                  />
                </clipPath>
              </defs>
            </svg>
            <span className='text-[#2797FF] text-[20px] font-semibold leading-[20px] tracking-[-0.5px]'>
              법인
            </span>
          </div>
          <HorizonOnline
            insuranceProducts_links={InsuranceProducts_links.corporation}
          />
          <HorizonTel
            insuranceProducts_phone={InsuranceProducts_phone.corporation}
          />
          <div className='bg-[#EDF2FD] rounded-[10px] p-4 flex items-center gap-3 mb-[30px] w-[1200px] justify-start'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <g clip-path='url(#clip0_2433_12023)'>
                <path
                  d='M16.661 18.3657H8.84766V4.29524C8.84766 3.58095 9.4267 3 10.1429 3H22.7048C23.4191 3 24 3.57905 24 4.29524V17.0705C24 17.7848 23.421 18.3657 22.7048 18.3657H21.5486'
                  fill='#9FB1FF'
                />
                <path
                  d='M1.71429 18.3655C0.767619 18.3655 0 17.5979 0 16.6512V11.1217C0 10.5331 0.354286 9.54643 1.04 8.52548C2.30095 6.64929 4.61524 5.56738 5.40952 5.56738H8.84762V18.3655H1.71429Z'
                  fill='#6270F4'
                />
                <path
                  d='M1.90479 11.8355V11.2013C1.90479 11.1289 1.91431 11.0603 1.93145 10.9917C1.99431 10.7555 2.17907 10.2451 2.62098 9.58982C3.40193 8.42601 4.78859 7.69459 5.34859 7.50982C5.42669 7.48506 5.50669 7.47363 5.58859 7.47363H6.12764C6.57907 7.47363 6.94288 7.83935 6.94288 8.28887V11.8355C6.94288 12.287 6.57717 12.6508 6.12764 12.6508H2.72002C2.26859 12.6508 1.90479 12.2851 1.90479 11.8355Z'
                  fill='white'
                />
                <path
                  d='M7.55022 18.3647C7.55022 19.7152 6.45688 20.8085 5.10641 20.8085C3.75593 20.8085 2.6626 19.7152 2.6626 18.3647C2.6626 17.0142 3.75593 15.9209 5.10641 15.9209C6.45688 15.9209 7.55022 17.0142 7.55022 18.3647Z'
                  fill='white'
                />
                <path
                  d='M5.91035 18.3644C5.91035 18.8082 5.55035 19.1682 5.10654 19.1682C4.66273 19.1682 4.30273 18.8082 4.30273 18.3644C4.30273 17.9205 4.66273 17.5605 5.10654 17.5605C5.55035 17.5605 5.91035 17.9205 5.91035 18.3644Z'
                  fill='#D0DEF7'
                />
                <path
                  d='M21.686 18.3647C21.686 19.7152 20.5926 20.8085 19.2421 20.8085C17.8917 20.8085 16.7983 19.7152 16.7983 18.3647C16.7983 17.0142 17.8917 15.9209 19.2421 15.9209C20.5926 15.9209 21.686 17.0142 21.686 18.3647Z'
                  fill='white'
                />
                <path
                  d='M20.0461 18.3644C20.0461 18.8082 19.6861 19.1682 19.2423 19.1682C18.7985 19.1682 18.4385 18.8082 18.4385 18.3644C18.4385 17.9205 18.7985 17.5605 19.2423 17.5605C19.6861 17.5605 20.0461 17.9205 20.0461 18.3644Z'
                  fill='#D0DEF7'
                />
                <path
                  d='M21.2383 14.2719H11.8288C11.4078 14.2719 11.0669 13.9309 11.0669 13.51C11.0669 13.089 11.4078 12.748 11.8288 12.748H21.2383C21.6593 12.748 22.0002 13.089 22.0002 13.51C22.0002 13.9309 21.6593 14.2719 21.2383 14.2719Z'
                  fill='#D9E3FF'
                />
                <path
                  d='M14.1278 11.4457H11.8288C11.4078 11.4457 11.0669 11.1047 11.0669 10.6838C11.0669 10.2628 11.4078 9.92188 11.8288 9.92188H14.1278C14.5488 9.92188 14.8898 10.2628 14.8898 10.6838C14.8898 11.1047 14.5488 11.4457 14.1278 11.4457Z'
                  fill='#D9E3FF'
                />
              </g>
              <defs>
                <clipPath id='clip0_2433_12023'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <span className='text-[#494AF1] text-[20px] font-semibold leading-[20px] tracking-[-0.5px]'>
              영업
            </span>
          </div>
          <HorizonOnline
            insuranceProducts_links={InsuranceProducts_links.business}
          />
          <HorizonTel
            insuranceProducts_phone={InsuranceProducts_phone.business}
          />
        </>
      )}

      <Notice />
    </>
  );
}
