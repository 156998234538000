import GoTopBtn from 'components/items/GoTopBtn';
import CarsayoFooter from './footer';
import CarsayoHeader from './header';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { isMobile, isBrowser } from 'react-device-detect';

export default function CarsayoWrap() {
  const location = useLocation();

  const mainRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    mainRef.current?.scrollTo({ top: 0, behavior: 'auto' });
  }, [location.pathname]);

  // useLayoutEffect(() => {
  //   const currentDomain = window.location.hostname;
  //   const currentPath = window.location.pathname + window.location.search;

  //   if (
  //     isMobile &&
  //     (currentDomain === 'carsayo.net' || currentDomain === 'www.carsayo.net')
  //   ) {
  //     window.location.replace('https://m.carsayo.net' + currentPath);
  //   } else if (isBrowser && currentDomain === 'm.carsayo.net') {
  //     window.location.replace('https://carsayo.net' + currentPath);
  //   }
  // }, []);

  return (
    <div
      id='main'
      ref={mainRef}
      className='overflow-y-scroll flex flex-auto flex-col text-[#222222] font-[400] text-[16px] w-dvw h-dvh relative'
    >
      <CarsayoHeader />
      <Outlet key={location.pathname} />
      <CarsayoFooter />
      <GoTopBtn />
    </div>
  );
}
