import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { cn } from 'util/index';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

interface HistoryDetailInterface {
  month: string;
  title: string;
  description: string | null;
  img: string | null;
  bgImg: string | null;
  item: React.ReactNode | null;
}

interface HistoryInterface {
  year: string;
  list: Array<HistoryDetailInterface>;
}

const businessList = [
  {
    title: `리스/장기렌트`,
    badge: null,
  },
  {
    title: `신차 구매`,
    badge: null,
  },
  {
    title: `슈퍼카 판매`,
    badge: null,
  },
  {
    title: `내 차 팔기`,
    badge: null,
  },
  {
    title: `다이렉트 자동차 보험`,
    badge: null,
  },
  {
    title: `자동차 수입/수출`,
    badge: null,
  },
  {
    title: `정비 및 사고수리 서비스 `,
    badge: null,
  },
  {
    title: `AI 차량 관리 및 비교 견적`,
    badge: '2025년 사업',
  },
  {
    title: `자동차 대출 서비스`,
    badge: '2026년 상반기 사업',
  },
];

// pc
const Section01 = () => {
  const [layoutLoad, setLayoutLoad] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setLayoutLoad(true);
    }
  }, [location]);

  return (
    <section
      className={cn(
        'w-[1200px] pb-[50px] min-h-[604px] mx-auto flex justify-center items-stretch transition-opacity duration-500',
        layoutLoad ? 'opacity-100' : 'opacity-20'
      )}
    >
      <div className='w-[620px] flex flex-col justify-between items-start bg-[#F8F9FA] py-[60px] pl-[50px] pr-[47px]'>
        <div>
          <strong className='mb-[30px] block text-[24px] leading-[38.4px] font-[600]'>
            자동차 판매 및 구매,
            <br />
            자동차 금융 및 보험, 앱 서비스 개발 외 다수
          </strong>
          <p className='text-[16px] leading-[25.6px] font-[500]'>
            카사요는 자동차 관련 모든 서비스를 전문적으로 제공하는 자동차 쇼핑
            통합 플랫폼으로 <br />
            참신하고 혁신적인 비즈니스 모델로 자동차 산업에 기여하고 있습니다.
            <br />
            정보의 비대칭을 해결하고 긍정적인 인식 변화를 이끌어 자동차 시장을
            선도할 것입니다.
          </p>
        </div>
        <div className='w-full flex justify-start items-center py-[1px] border-l-[2px] border-[#000000] gap-[46px] pl-[24px]'>
          <div className='flex flex-col justify-start items-start gap-[20px]'>
            <span className='text-[15px] leading-[15px]'>회사명</span>
            <strong className='text-[18px] leading-[18px] font-[600]'>
              카사요(주)
            </strong>
          </div>
          <div className='flex flex-col justify-start items-start gap-[20px]'>
            <span className='text-[15px] leading-[15px]'>대표이사</span>
            <strong className='text-[18px] leading-[18px] font-[600]'>
              최용민
            </strong>
          </div>
          <div className='flex flex-col justify-start items-start gap-[20px]'>
            <span className='text-[15px] leading-[15px]'>설립일</span>
            <strong className='text-[18px] leading-[18px] font-[600]'>
              2020년 8월 7일
            </strong>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: 'url(../imgs/png/company/overview_section01_bg.png)',
        }}
        className='w-[580px] bg-no-repeat bg-cover bg-center flex justify-center items-center'
      ></div>
    </section>
  );
};
const Section02 = () => {
  return (
    <section className='w-[1200px] mx-auto py-[50px]'>
      <div className='mb-[40px] flex flex-col justify-start items-start gap-[30px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[20px] leading-[20px] font-[600]'
        >
          사업 영역
        </motion.span>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        style={{
          backgroundImage: 'url(../imgs/png/company/overview_section02_bg.png)',
        }}
        className='w-full flex justify-start items-center gap-[48px] bg-cover bg-center bg-no-repeat'
      >
        <ul className='w-full grid grid-cols-3 text-white '>
          {businessList.map((item, index) => (
            <li
              className={cn(
                'relative h-[180px] hover:font-[700] flex justify-center items-center border-t border-r text-[20px] leading-[20px] font-[500] text-[#fff] transition-all duration-300',
                (index === 0 || index === 3 || index === 6) && 'border-l',
                index > 5 && 'border-b'
              )}
            >
              {item.title}
              {item.badge && (
                <div className='absolute rounded-[8px] top-4 left-4 px-2 py-2.5 bg-[#FFFFFF33] backdrop-blur-[30px] text-[15px] leading-[15px] font-[500] hover:font-[500] text-[#555555]'>
                  <span className='text-white'>{item.badge}</span>
                </div>
              )}
            </li>
          ))}
        </ul>
      </motion.div>
    </section>
  );
};
const Section03 = ({ historyList }: { historyList: HistoryInterface[] }) => {
  return (
    <section className='w-[1200px] mx-auto  py-[50px]'>
      <div className=' mb-[40px] flex flex-col justify-start items-start gap-[30px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[20px] leading-[20px] font-[600]'
        >
          기업 연혁
        </motion.span>
      </div>
      <div className='w-full bg-[#F8F9FA] py-[40px] px-[30px]'>
        {historyList.map((item, index) => (
          <div
            key={`history_item_${index}`}
            className='flex flex-col justify-start items-start gap-[30px] mb-[50px]'
          >
            <motion.strong
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='text-[24px] leading-[24px] font-[700]'
            >
              {item.year}
            </motion.strong>
            <ul
              className={cn(
                'w-full grid grid-cols-2',
                item.list.length > 1 && '[&>*:nth-child(odd)]:border-r-[1px]'
              )}
            >
              {item.list.map((list, index) => (
                <motion.li
                  initial={{ opacity: 0 }}
                  whileInView={{
                    opacity: 1,
                    transition: { delay: 0.1, duration: 0.7 },
                  }}
                  key={`history_detail_${index}`}
                  className={cn(
                    'w-full bg-white p-[20px] flex flex-col justify-start items-start gap-[20px]',
                    index < item.list.length - 2 &&
                      'border-[#EEEEEE] border-b-[1px]',
                    item.year === '2024년' &&
                      index === 0 &&
                      'shadow-[0px_4px_30px_#0000001A] z-[1]'
                  )}
                >
                  <div
                    style={{
                      backgroundImage: list.bgImg
                        ? `url(${list.bgImg})`
                        : 'none',
                    }}
                    className={cn(
                      'w-full aspect-[530/260] p-[30px] flex justify-center items-center',
                      list.bgImg
                        ? `bg-cover bg-center bg-no-repeat`
                        : 'bg-[#F8F9FA]',
                      list.item && 'p-0'
                    )}
                  >
                    {list.item ? (
                      list.item
                    ) : list.img ? (
                      <img
                        src={list.img}
                        alt={`${list.title}이미지`}
                        className='w-[150px] h-[200px] object-fill'
                      />
                    ) : (
                      list.bgImg === null && (
                        <img
                          src='../imgs/png/company/overview_section03_defaultImg.png'
                          alt={`기본 이미지`}
                          className='w-[50px] object-contain'
                        />
                      )
                    )}
                  </div>
                  <div className='flex justify-start items-center gap-[34px]'>
                    <span>{list.month}</span>
                    <p className='flex justify-center items-center divide-x-[1px] border-[#CCCCCC] text-[18px] leading-[18px]'>
                      <strong className='pr-2 font-[600 ]'>{list.title}</strong>
                      {list.description && (
                        <span className='pl-2'>{list.description}</span>
                      )}
                    </p>
                  </div>
                </motion.li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};
// 모바일
const MSection01 = () => {
  const [layoutLoad, setLayoutLoad] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setLayoutLoad(true);
    }
  }, [location]);
  return (
    <section
      className={cn(
        'w-full flex flex-col justify-start items-start transition-opacity duration-500',
        layoutLoad ? 'opacity-100' : 'opacity-20'
      )}
    >
      <div
        style={{
          backgroundImage: 'url(../imgs/png/company/overview_section01_bg.png)',
        }}
        className='w-full aspect-[375/417] bg-no-repeat bg-cover bg-bottom flex justify-center items-center'
      ></div>
      <div className='w-full flex flex-col justify-between items-start bg-[#F8F9FA] py-[50px] px-[20px]'>
        <div>
          <strong className='mb-4 block text-[18px] leading-[28.8px] font-[600] text-[#000] break-keep'>
            자동차 판매 및 구매,
            <br />
            자동차 금융 및 보험, 앱 서비스 개발 외 다수
          </strong>
          <p className='text-[#000] text-[16px] leading-[25.6px] break-keep mb-[62px] max-w-[335px]'>
            카사요는 자동차 관련 모든 서비스를 전문적으로 제공하는 자동차 쇼핑
            통합 플랫폼으로 참신하고 혁신적인 비즈니스 모델로 자동차 산업에
            기여하고 있습니다. 정보의 비대칭을 해결하고 긍정적인 인식 변화를
            이끌어 자동차 시장을 선도할 것입니다.
          </p>
        </div>
        <div className='w-full flex justify-between items-center py-[1px] border-l-[2px] border-[#000000] gap-[10px] pl-[24px]'>
          <div className='flex flex-col justify-start items-start gap-[20px]'>
            <span className='text-[15px] leading-[15px]'>회사명</span>
            <strong className='text-[16px] leading-[16px] font-[600]'>
              카사요(주)
            </strong>
          </div>
          <div className='flex flex-col justify-start items-start gap-[20px]'>
            <span className='text-[15px] leading-[15px]'>대표이사</span>
            <strong className='text-[16px] leading-[16px] font-[600]'>
              최용민
            </strong>
          </div>
          <div className='flex flex-col justify-start items-start gap-[20px]'>
            <span className='text-[15px] leading-[15px]'>설립일</span>
            <strong className='text-[16px] leading-[16px] font-[600]'>
              2020년 8월 7일
            </strong>
          </div>
        </div>
      </div>
    </section>
  );
};
const MSection02 = () => {
  return (
    <section className='w-full pt-[50px]'>
      <div className='mb-[30px] px-[20px] flex flex-col justify-start items-start gap-[30px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[14px] leading-[14px] font-[600]'
        >
          사업 영역
        </motion.span>
      </div>
      <div
        style={{
          backgroundImage: 'url(../imgs/png/company/overview_section02_bg.png)',
        }}
        className='w-full flex justify-start items-center gap-[48px] bg-cover bg-center bg-no-repeat'
      >
        <ul className='w-full flex flex-col justify-start items-start text-white '>
          {businessList.map((item, index) => (
            <motion.li
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: { delay: 0, duration: 0.7 },
              }}
              className={cn(
                'w-full relative min-h-[70px]  hover:font-[700] flex justify-center items-center text-[16px] leading-[16px] font-[500] text-[#fff] border-b last:border-b-0 transition-all duration-300 p-[20px]'
              )}
            >
              {item.badge ? (
                <div className='w-full flex justify-center tiny:justify-end items-center gap-[10px]'>
                  <div className='tiny:relative my-auto h-[30px] inline-block rounded-[8px] px-[6px] py-2 bg-[#FFFFFF33] backdrop-blur-[30px] text-[13px] leading-[13px] font-[500] hover:font-[500] text-[#555555]'>
                    <span className='text-white'>{item.badge}</span>
                  </div>
                  {item.title}
                </div>
              ) : (
                <span>{item.title}</span>
              )}
            </motion.li>
          ))}
        </ul>
      </div>
    </section>
  );
};
const MSection03 = ({ historyList }: { historyList: HistoryInterface[] }) => {
  return (
    <section className='w-full pt-[50px]'>
      <div className='mb-[30px] px-[20px] flex flex-col justify-start items-start gap-[30px]'>
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#6E77F6] text-[14px] leading-[14px] font-[600]'
        >
          기업 연혁
        </motion.span>
      </div>
      <div className='w-full bg-[#F8F9FA] py-[30px] px-[20px]'>
        {historyList.map((item, index) => (
          <div
            key={`history_item_${index}`}
            className='flex flex-col justify-start items-start gap-[24px] mb-[50px] last:mb-[20px]'
          >
            <motion.strong
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='text-[20px] leading-[20px] font-[700]'
            >
              {item.year}
            </motion.strong>
            <ul
              className={cn(
                'w-full flex flex-col justify-start items-start gap-[20px]'
              )}
            >
              {item.list.map((list, index) => (
                <motion.li
                  initial={{ opacity: 0 }}
                  whileInView={{
                    opacity: 1,
                    transition: { delay: 0, duration: 0.7 },
                  }}
                  key={`history_detail_${index}`}
                  className={cn(
                    'w-full bg-white p-4 flex flex-col justify-start items-start gap-4',
                    item.year === '2024년' &&
                      index === 0 &&
                      'shadow-[0px_4px_20px_#0000001A]'
                  )}
                >
                  <div
                    style={{
                      backgroundImage: list.bgImg
                        ? `url(${list.bgImg})`
                        : 'none',
                    }}
                    className={cn(
                      'w-full aspect-[303/180] p-[20px] flex justify-center items-center',
                      list.bgImg
                        ? `bg-cover bg-center bg-no-repeat`
                        : 'bg-[#F8F9FA]',
                      list.item && 'p-0'
                    )}
                  >
                    {list.item ? (
                      list.item
                    ) : list.img ? (
                      <img
                        src={list.img}
                        alt={`${list.title}이미지`}
                        className='w-[105px] h-[140px] object-fill'
                      />
                    ) : (
                      list.bgImg === null && (
                        <img
                          src='../imgs/png/company/overview_section03_defaultImg.png'
                          alt={`기본 이미지`}
                          className='w-[40px] object-contain'
                        />
                      )
                    )}
                  </div>
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: { delay: 0.1, duration: 0.5 },
                    }}
                    className='w-full flex justify-start items-center gap-[20px]'
                    key={`${list.month}_${list.title}`}
                  >
                    <span
                      className={cn(
                        'text-[16px] leading-[24px] relative min-w-[48px]',
                        'after:content-[""] after:absolute after:w-[1px] after:h-[16px] after:bg-[#CCCCCC] after:top-0 after:bottom-0 after:my-auto after:right-0'
                      )}
                    >
                      {list.month}
                    </span>
                    <div className='flex flex-col justify-center items-start'>
                      <span className='flex justify-center items-center text-[16px] leading-[24px] font-[600] break-keep'>
                        {list.title}
                      </span>
                      {list.description && (
                        <span className='text-[16px] leading-[24px] break-keep mt-[6px] flex justify-center items-center'>
                          {list.description}
                        </span>
                      )}
                    </div>
                  </motion.div>
                </motion.li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default function Overview() {
  const audioFiles = [
    '../audio/carsayoA.wav',
    '../audio/carsayoB.wav',
    '../audio/carsayoC.wav',
    '../audio/carsayoD.wav',
  ];

  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioPlay, setAudioPlay] = useState<boolean | null>(null);

  const playRandomAudio = () => {
    if (audioPlay) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setAudioPlay(null);
    } else {
      const randomIndex = Math.floor(Math.random() * audioFiles.length);
      if (audioRef.current) {
        audioRef.current.src = audioFiles[randomIndex];
        audioRef.current
          .play()
          .then(() => {
            setAudioPlay(true);
          })
          .catch((error) => {
            console.error('오디오 재생 중 오류 발생:', error);
          });
      }
    }
  };
  const historyList: HistoryInterface[] = [
    {
      year: '2024년',
      list: [
        {
          month: '12월',
          title: '지식재산경영 기업 인증',
          description: '특허청 인증 획득',
          img: '../imgs/png/company/history/cert_img.png',
          bgImg: null,
          item: null,
        },
        {
          month: '12월',
          title: '가족친화기업 인증',
          description: '여성가족부 인증 획득',
          img: '../imgs/png/company/history/family_friendly.png',
          bgImg: null,
          item: null,
        },
        {
          month: '12월',
          title: `소비자중심경영(CCM) 기업 인증`,
          description: '공정거래위원회 인증 획득',
          img: '../imgs/png/company/history/ccm_img.png',
          bgImg: null,
          item: null,
        },
        {
          month: '11월',
          title: `직무발명보상 우수기업 인증`,
          description: '특허청 인증 획득',
          img: '../imgs/png/company/history/employee_invention.png',
          bgImg: null,
          item: null,
        },
        {
          month: '11월',
          title: `ISO 37301 (준법경영시스템)`,
          description: '인증 획득',
          img: null,
          bgImg: null,
          item: null,
        },
        {
          month: '11월',
          title: `ISO 37001 (부패방지경영시스템)`,
          description: '인증 획득',
          img: null,
          bgImg: null,
          item: null,
        },
        {
          month: '6월',
          title: `공식 카사요 앱 출시`,
          description: null,
          img: null,
          bgImg: '../imgs/png/company/history/year24_carsayoBg.png',
          item: null,
        },
        {
          month: '5월',
          title: `벤처 기업 인증 (혁신 성장형)`,
          description: `중소벤처기업부 벤처인증 획득`,
          img: '../imgs/png/company/history/ventureImg.png',
          bgImg: null,
          item: null,
        },
        {
          month: '5월',
          title: `기업 부설 연구소 설립`,
          description: `과학기술정보통신부 인증 획득`,
          img: '../imgs/png/company/history/laboratoryImg.png',
          bgImg: null,
          item: null,
        },
        {
          month: '1월',
          title: `ISO 9001 (품질경영시스템)`,
          description: `인증 획득`,
          img: '../imgs/png/company/history/certificateImg01.png',
          bgImg: null,
          item: null,
        },
        {
          month: '1월',
          title: `ISO 14001 (환경경영시스템)`,
          description: `인증 획득`,
          img: '../imgs/png/company/history/certificateImg02.png',
          bgImg: null,
          item: null,
        },
      ],
    },
    {
      year: '2023년',
      list: [
        {
          month: '12월',
          title: '대출성 금융 상품판매대리 중개업 등록',
          description: null,
          img: '../imgs/png/company/history/year23_item01.png',
          bgImg: null,
          item: null,
        },
        {
          month: '11월',
          title: '연구 개발 전담 부서 설립',
          description: null,
          img: '../imgs/png/company/history/year23_item02.png',
          bgImg: null,
          item: null,
        },
      ],
    },
    {
      year: '2022년',
      list: [
        {
          month: '7월',
          title: '라디오 광고',
          description: '가수 배기성 - 나레이션 및 노래',
          img: null,
          bgImg: null,
          item: (
            <div
              onClick={playRandomAudio}
              style={{
                backgroundImage: "url('../imgs/png/audio_bg.png')",
              }}
              className={cn(
                'relative border-0 cursor-pointer w-full h-full flex justify-center items-center bg-no-repeat bg-cover bg-center bg-blend-multiply',
                audioPlay === true ? 'bg-transparent' : 'bg-[#0000001A]'
              )}
            >
              <button>
                <div className='flex flex-col justify-center items-center gap-8'>
                  {audioPlay ? (
                    <span className='w-[50px] h-[50px]'>
                      <img
                        src='../imgs/icons/stop.png'
                        alt='정지 아이콘'
                        className='w-full object-contain'
                      />
                    </span>
                  ) : (
                    <span className='flex justify-center items-center w-[50px] h-[50px]'>
                      <img
                        src='../imgs/icons/play.png'
                        alt='오디오 재생 아이콘'
                        className='w-full object-contain'
                      />
                    </span>
                  )}
                  <ul
                    className={`audio-visualizer flex justify-center items-center transition-all ${
                      audioPlay
                        ? 'playing opacity-100 absolute bottom-[20px]'
                        : 'opacity-0 absolute bottom-[0px] z-[-999]'
                    }`}
                  >
                    {/* 이거 있어야 이퀄라이저 돌아감!! */}
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>

                <audio ref={audioRef} loop />
              </button>
            </div>
          ),
        },
      ],
    },
    {
      year: '2021년',
      list: [
        {
          month: '11월',
          title: '카사요 앱 Android 버전 출시',
          description: null,
          img: null,
          bgImg: isMobile
            ? '../imgs/png/company/history/m_year21_itemBg.png'
            : '../imgs/png/company/history/year21_itemBg.png',
          item: null,
        },
      ],
    },
    {
      year: '2020년',
      list: [
        {
          month: '12월',
          title: '카사요 앱 iOS 버전 출시',
          description: null,
          img: null,
          bgImg: isMobile
            ? '../imgs/png/company/history/m_year20_itemBg01.png'
            : '../imgs/png/company/history/year20_itemBg01.png',
          item: null,
        },
        {
          month: '8월',
          title: '카사요(주) 설립',
          description: null,
          img: null,
          bgImg: '../imgs/png/company/history/year20_itemBg02.png',
          item: null,
        },
      ],
    },
  ];
  return (
    <>
      <BrowserView className='py-[50px]'>
        <Section01 />
        <Section02 />
        <Section03 historyList={historyList} />
      </BrowserView>
      <MobileView className='w-dvw'>
        <MSection01 />
        <MSection02 />
        <MSection03 historyList={historyList} />
      </MobileView>
    </>
  );
}
