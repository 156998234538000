import { cn } from 'util/index';
import { ccmTags, list } from '../main';
import { motion } from 'framer-motion';
import { SButton_공정거래위원회, SButton_한국소비자원 } from 'pages/ccm/button';
import { Link } from 'react-router-dom';

export default function MSection02() {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: { delay: 0, duration: 0.7 },
      }}
      className='w-[100dvw] pt-[50px] px-[20px]'
    >
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: { delay: 0, duration: 0.5 },
        }}
        className='pl-[10px] text-[20px] leading-[30px] font-[600] mb-[20px]'
      >
        카사요는 왜 다른가요?
      </motion.div>

      <div>
        <div className='bg-[#F8F9FA] pt-5 pb-6 border-b border-[#EEEEEE] flex flex-col'>
          <div className='w-full flex justify-end pb-4'>
            <div className='text-[#222] font-Pretendard text-[14px] font-semibold leading-[21px] tracking-[-0.35px] rounded-l-[10px] bg-[#EEF0F2] w-[160px] py-2 pl-3 pr-6'>
              소비자가 신뢰하는 기업
            </div>
          </div>

          <Link to={'/ccm'}>
            <div className='flex-1 px-6 '>
              <div className='font-semibold text-[15px] leading-[22.5px] bg-gradient-to-r from-[#21AEFF] to-[#6465FF] bg-clip-text mb-[4px] text-transparent max-w-[200px]'>
                자동차 플랫폼 업계 최초&유일
              </div>

              <div className='font-semibold text-[20px] leading-[32px]'>
                국가공인제도
                <br />
                소비자 중심경영(CCM) 인증
              </div>

              <ul className='flex gap-[6px] flex-wrap mt-[24px]'>
                {ccmTags.map((item, index) => (
                  <li
                    key={`mccmTag_${index}`}
                    className={cn(
                      'font-[500] text-[14px] leading-[14px] p-[10px] bg-white text-[#555555] rounded-[10px]',
                      item.style
                    )}
                  >
                    {item.title}
                  </li>
                ))}
              </ul>

              <div className='bg-[#EEF0F2] py-4 px-[17px] tiny:text-[12px] rounded-[10px] font-semibold text-[15px] leading-[24px] max-w-[615px] my-[20px]'>
                업계 단독 “자동차보험 중개 플랫폼(웹/앱)”
                <br />
                특허 보유 -{' '}
                <span className='font-semibold text-[15px] tiny:text-[12px] leading-[24px] text-[#0079FC]'>
                  특허 제 10-2761390 호
                </span>
              </div>
            </div>
          </Link>

          <div className='flex gap-4 px-6'>
            <div className='flex flex-col gap-[6px]'>
              <SButton_공정거래위원회 className='max-w-[127px] bg-white border-[#EEEEEE] border h-[97px] gap-[17px] pt-[23px]' />
              <SButton_한국소비자원 className='max-w-[127px] bg-white border-[#EEEEEE] border h-[97px] gap-[22px] pt-[23px]' />
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                window.open('https://www.joongang.co.kr/article/25298873');
              }}
              className='cursor-pointer'
            >
              <img
                src='../imgs/png/company/history/ccm_img.png'
                alt='인증서 이미지'
                className='w-[150px] h-[200px] rounded-[8px] border border-[#00000066]'
                style={{ boxShadow: '0px 6.25px 18.75px 0px #00000026' }}
              />
            </div>
          </div>
        </div>
        <ul>
          {list.map((item, index) => (
            <motion.li
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: { delay: 0, duration: 0.7 },
              }}
              key={`section02List_${item.title}`}
              className={cn(
                'p-6 min-h-[280px] flex flex-col justify-start items-start relative gap-[10px] bg-[#F8F9FA] border-[#EEEEEE] border-b last:border-b-0'
              )}
            >
              <span className='min-w-[46px] mb-2.5 text-[15px] leading-[15px] font-[700] inline-flex justify-center items-center py-[6px] px-[11px] bg-[#222] rounded-[100px] text-white'>
                0{index + 1}
              </span>
              <div className='w-full relative z-[1]'>
                <strong className='text-[20px] leading-[32px] font-[600] whitespace-pre-line'>
                  {item.title}
                </strong>
                {item.point && (
                  <span className='mt-[5.5px] flex justify-start items-center pl-2 text-[#000] border-[#222] border-l-[3px] text-[16px] leading-[19px] font-[500]'>
                    {item.point}
                  </span>
                )}
              </div>
              <p
                className={cn(
                  'text-[16px] leading-[25.6px] font-[400] break-keep max-w-[270px] whitespace-pre-line relative z-[1]',
                  index === list.length - 1 && 'max-w-[250px] whitespace-normal'
                )}
              >
                {item.description}
              </p>
              <div
                className={cn(
                  'flex flex-col justify-center items-center gap-[12px] absolute bottom-[4px] right-[16px] z-0',
                  index === list.length - 1 && 'bottom-0'
                )}
              >
                <img
                  className='h-[58px] object-contain'
                  src={item.imgUrl}
                  alt={`${item.title}_이미지`}
                />
                <img
                  src='../imgs/png/main_section02_shadow.png'
                  alt='그림자'
                  className='w-[60px] object-contain'
                />
              </div>
            </motion.li>
          ))}
        </ul>
      </div>
    </motion.section>
  );
}
