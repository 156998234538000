/* eslint-disable react-hooks/exhaustive-deps */
import { isAndroid, isBrowser, isIOS, isMobile } from 'react-device-detect';
import NoticePopup from './components/NoticePopup';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Check, Loader2 } from 'lucide-react';
import { cn } from 'util/index';
import { api } from 'apis';
import { InsuranceCompany } from './components/types';
import InsuranceProductList from './components/Insurance';

const HandIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <g clipPath='url(#clip0_1789_23365)'>
        <path
          d='M5.33301 8.66667V3C5.33301 2.73478 5.43836 2.48043 5.6259 2.29289C5.81344 2.10536 6.06779 2 6.33301 2C6.59822 2 6.85258 2.10536 7.04011 2.29289C7.22765 2.48043 7.33301 2.73478 7.33301 3V8'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.33301 7.66732V6.33398C7.33301 6.06877 7.43836 5.81441 7.6259 5.62688C7.81344 5.43934 8.06779 5.33398 8.33301 5.33398C8.59822 5.33398 8.85258 5.43934 9.04011 5.62688C9.22765 5.81441 9.33301 6.06877 9.33301 6.33398V8.00065'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.33301 7C9.33301 6.73478 9.43836 6.48043 9.6259 6.29289C9.81344 6.10536 10.0678 6 10.333 6C10.5982 6 10.8526 6.10536 11.0401 6.29289C11.2277 6.48043 11.333 6.73478 11.333 7V8'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.3335 7.66602C11.3335 7.4008 11.4388 7.14645 11.6263 6.95891C11.8139 6.77137 12.0682 6.66602 12.3335 6.66602C12.5987 6.66602 12.853 6.77137 13.0406 6.95891C13.2281 7.14645 13.3335 7.4008 13.3335 7.66602V10.666C13.3335 11.7269 12.912 12.7443 12.1619 13.4944C11.4117 14.2446 10.3943 14.666 9.33346 14.666H8.00012H8.13879C7.47635 14.6661 6.82425 14.5017 6.24104 14.1875C5.65784 13.8734 5.1618 13.4193 4.79746 12.866L4.66679 12.666C4.45879 12.3467 3.72879 11.074 2.47612 8.84735C2.34842 8.62037 2.31431 8.35253 2.38104 8.10078C2.44778 7.84904 2.61009 7.63327 2.83346 7.49935C3.07137 7.35659 3.35017 7.29743 3.62556 7.33125C3.90095 7.36507 4.15715 7.48993 4.35346 7.68602L5.33346 8.66602'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.33366 2.00065L2.66699 1.33398'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.66667 4.66602H2'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.33301 2.00065L9.99967 1.33398'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 4H10.6667'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1789_23365'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

const menuInfo = [
  {
    title: '다이렉트 자동차보험',
    desc: '',
    type: 'insurance',
    img: '/imgs/png/service_insurance.png',
  },
  {
    title: '신차 구매',
    desc: '원하는 차량의 견적을 한 번에!',
    type: 'newcar',
    img: '/imgs/png/service_newcar.png',
  },
  {
    title: '내 차 팔기',
    desc: '시세부터 판매까지 감가 없이 안전하게!',
    type: 'sellcar',
    img: '/imgs/png/service_selling.png',
  },
  {
    title: '자동차 사고수리',
    desc: '카사요가 엄선한 1급 공업사',
    type: 'repair',
    img: '/imgs/png/service_repair.png',
  },
];

// 개인 및 법인(영업)용 보험 비교 탭
export type InsuranceTypeTab = 'personal' | 'business';
export type InsuranceProductsData = {
  default: InsuranceCompany[];
  corporation: InsuranceCompany[];
  business: InsuranceCompany[];
};

function BrowserServiceUsage({
  insuranceTypeTab,
  setInsuranceTypeTab,
}: {
  insuranceTypeTab: InsuranceTypeTab;
  setInsuranceTypeTab: (insuranceTypeTab: InsuranceTypeTab) => void;
}) {
  const searchParams = new URLSearchParams(useLocation().search);
  const queryValue = searchParams.get('param') as
    | 'newcar'
    | 'sellcar'
    | 'insurance'
    | 'repair'
    | undefined;

  const [isLoading, setIsLoading] = useState(true);
  const [insuranceProducts, setInsuranceProducts] =
    useState<InsuranceProductsData>({
      default: [],
      corporation: [],
      business: [],
    });
  const [selectedInsuranceId, setselectedInsuranceIdId] = useState<number>(0); // 선택된 보험사 상품 id

  const initSrc =
    queryValue === 'newcar'
      ? 'https://app.carsayo.net'
      : queryValue === 'sellcar'
        ? 'https://app.carsayo.net'
        : queryValue === 'insurance'
          ? 'https://app.carsayo.net/static/insurance'
          : queryValue === 'repair'
            ? 'https://app.carsayo.net/static/repairshop'
            : 'https://app.carsayo.net';

  const [activeTab, setActiveTab] = useState<
    'newcar' | 'sellcar' | 'insurance' | 'repair' | undefined
  >(queryValue || 'insurance'); // TODO 디폴트 화면

  const isInsurance = activeTab === 'insurance';

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const navigateIframe = (path: string) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        { type: 'navigate', path },
        '*'
      );
    }
  };

  const baseUrl = 'https://insu.coocon.net/bridge.act';
  const isApp = isAndroid || isIOS;
  const orgCd = isApp ? 'RUQBBRKU' : 'VX2mMRCM';
  const browserType = isApp ? '2' : '3';

  const href = `${baseUrl}?org_cd=${orgCd}&browser_type=${browserType}`;

  // iframe 로딩 완료 시 실행할 함수
  const handleIframeLoad = () => {
    setTimeout(() => {
      if (activeTab === 'newcar') {
        // navigateIframe(`/auth/login`);
      }
      if (activeTab === 'sellcar') {
        // navigateIframe(`/auth/login`);
      }
      if (activeTab === 'insurance') {
        navigateIframe(`/static/insurance`);
      }
      if (activeTab === 'repair') {
        navigateIframe(`/static/repairshop`);
      }
    }, 500);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleGetInsuranceProduct = async () => {
    try {
      const isDevelopment = searchParams.get('dev') === 'true';

      const targetApiUrl = isDevelopment
        ? 'https://dev.be.carsayo.net/data/insurance/products'
        : 'https://api.carsayo.net/data/insurance/products';

      const targetApiUrl2 = isDevelopment
        ? 'https://dev.be.carsayo.net/data/insurance/products/corporation'
        : 'https://api.carsayo.net/data/insurance/products/corporation';

      const targetApiUrl3 = isDevelopment
        ? 'https://dev.be.carsayo.net/data/insurance/products/business'
        : 'https://api.carsayo.net/data/insurance/products/business';

      const [defaultRes, corporationRes, businessRes] = await Promise.all([
        api.get(targetApiUrl),
        api.get(targetApiUrl2),
        api.get(targetApiUrl3),
      ]);

      const data: InsuranceProductsData = {
        default: defaultRes.data,
        corporation: corporationRes.data,
        business: businessRes.data,
      };

      setInsuranceProducts(data);
    } catch (e) {}
  };

  useEffect(() => {
    handleIframeLoad();
  }, [activeTab]);

  useEffect(() => {
    handleGetInsuranceProduct();
  }, []);

  return (
    <div className='w-full flex flex-col'>
      <div className='relative w-[1200px] mx-auto flex justify-between items-center pt-[132px] pb-[56px] flex-none'>
        <div className='flex flex-col gap-4'>
          <div className='font-[700] text-[32px] leading-[48px]'>
            {menuInfo.filter((menu) => menu.type === activeTab)[0].title}
          </div>
          <div className='font-[500] text-[24px] leading-[24px]'>
            {menuInfo.filter((menu) => menu.type === activeTab)[0].desc}

            {isInsurance && (
              <div className='flex flex-col gap-4'>
                <div className='flex items-center gap-2 text-[18px] font-semibold leading-[150%]'>
                  <img
                    src='/imgs/icons/exc_icon.png'
                    className='w-6 h-6'
                    alt='보험사'
                  />
                  꼭 확인하세요
                </div>
                <div className='flex flex-col gap-3'>
                  {(
                    [
                      {
                        text: '법인 및 영업용 차량은 보험료 한 번에 비교하기 서비스가 제공되지 않습니다.',
                        tab: ['business'],
                      },
                      {
                        text: '보험료 한 번에 비교하기',
                        subText: '온라인&전화 가입',
                        additionalText: '보다 보험료가 조금 높습니다.',
                        tab: ['personal'],
                      },
                      {
                        text: '보험료 한 번에 비교하기',
                        subText: '온라인&전화 가입',
                        additionalText:
                          '의 보험사를 직접 선택하시어<br/>국내 최저가 다이렉트 자동차 보험을 계약하세요.',
                        breakLine: true,
                        tab: ['personal'],
                      },
                    ] as {
                      text: string;
                      subText?: string;
                      additionalText?: string;
                      breakLine?: boolean;
                      tab: InsuranceTypeTab[];
                    }[]
                  ).map(
                    (
                      { text, subText, additionalText, breakLine, tab },
                      index
                    ) => (
                      <div
                        key={index}
                        className={cn(
                          'flex items-start gap-[10px] text-[18px] font-[500] leading-[160%]',
                          !tab.includes(insuranceTypeTab) && 'hidden'
                        )}
                      >
                        <div className='h-4 w-4 mt-[2px]'>
                          <Check
                            width={20}
                            height={20}
                            strokeWidth={1.67}
                            color='#FF257C'
                          />
                        </div>
                        <div>
                          <span className='font-[700]'>{text}</span>
                          {index === 1
                            ? '는'
                            : index === 2
                              ? '에서 비교만 하시고'
                              : ''}{' '}
                          <span className='font-[700] text-[#FF257C]'>
                            {subText}
                          </span>
                          {additionalText && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: additionalText,
                              }}
                            ></span>
                          )}
                          {breakLine && <br />}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {insuranceTypeTab === 'personal' && (
          <Link
            to={isInsurance ? href : '#'}
            onClick={() => {
              if (!isInsurance) {
                setActiveTab('insurance');
                setInsuranceTypeTab('business');
              }
            }}
            target={!isInsurance ? '_self' : '_blank'}
            className={cn(
              'relative  rounded-[16px] p-5 cursor-pointer  w-[385px]',
              isInsurance
                ? 'bg-[#F8F9FA] hover:bg-gray-100'
                : 'bg-[#FEF6E5] hover:bg-[#FDECCE]'
            )}
          >
            <div className='flex flex-col gap-[9px]'>
              <div className='text-[#222] text-[15px] font-semibold leading-[150%] tracking-[-0.375px]'>
                {isInsurance ? (
                  <>
                    다이렉트 자동차보험 7개 보험사
                    <br />
                    <div className='text-[#0079FC] inline-flex items-center gap-0.5'>
                      한 번에 비교하기 <HandIcon />
                    </div>
                  </>
                ) : (
                  <>
                    국내 최저가 다이렉트 자동차 보험
                    <br />
                    <div className='text-[#B06D00] inline-flex items-center gap-0.5'>
                      개인·사업자·영업용 보험료 확인하기{' '}
                      <img
                        src='/imgs/png/right-arrow.png'
                        alt='right-arrow'
                        width={16}
                        height={16}
                      />
                    </div>
                  </>
                )}
              </div>
              <div
                className='text-[#999] text-[10px] font-normal leading-[130%] tracking-[-0.2px]'
                dangerouslySetInnerHTML={{
                  __html: isInsurance
                    ? '손해보험협회 심의필 제129622호<br/>(2024.08.01 ~ 2025.07.31)'
                    : '보험사 직계약 체결로 합리적인 보험료를 제시합니다.<br/>국내 모든 다이렉트 자동차 보험사를 만나보세요.',
                }}
              ></div>
            </div>
            <div
              className={
                isInsurance
                  ? 'absolute right-3 bottom-3'
                  : 'absolute right-5 bottom-4'
              }
            >
              <img
                className={
                  isInsurance ? 'w-[164px] h-[44px]' : 'w-[90px] h-[90px]'
                }
                src={
                  isInsurance
                    ? '/imgs/icons/services/companyIcons.png'
                    : '/imgs/png/service/banner_img.png'
                }
                alt='보험사'
              />
            </div>
          </Link>
        )}
        <div className='absolute top-0 w-full flex items-center justify-center my-5'>
          <ul className='flex gap-[10px] border-[1px] border-[#EEEEEE] rounded-[10px] p-[6px]'>
            {menuInfo
              // .filter((menu) => menu.type !== activeTab)
              .map((menu, index, filteredMenu) => (
                <li
                  key={`service_menu_${menu.type}`}
                  className='flex gap-[10px] cursor-pointer items-center'
                  onClick={() => {
                    setActiveTab(
                      menu.type as 'newcar' | 'sellcar' | 'insurance' | 'repair'
                    );
                    setselectedInsuranceIdId(0);
                    window.location.replace(
                      `${window.location.pathname}?param=${menu.type}`
                    );
                  }}
                >
                  <div
                    className={cn(
                      'flex gap-[6px] items-center font-[600] text-[13px] leading-[13px] py-[10px] px-[8px]',
                      menu.type === activeTab && 'bg-[#F0F3F6] rounded-[8px]'
                    )}
                  >
                    <img alt={menu.title} src={menu.img} className='w-5 h-5' />
                    {menu.title}
                  </div>
                  {index < filteredMenu.length - 1 && (
                    <div className='h-[20px] w-[1px] bg-[#EEEEEE]'></div>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div
        className={cn(
          'relative flex-grow flex flex-col items-center justify-center w-full h-full min-w-[1260px] min-h-[1500px]',
          activeTab !== 'insurance' && 'min-h-[1000px]'
        )}
      >
        {activeTab !== 'insurance' ? (
          <div className='flex gap-[250px] items-center py-9'>
            <div className='relative aspect-[340/736] z-[1]'>
              {/* Lodaer */}
              {isLoading && (
                <div className='w-full h-full flex justify-center items-center z-10 absolute rounded-[18.1px] bg-black/25'>
                  <Loader2 className='animate-spin' size={24} />
                </div>
              )}
              <iframe
                ref={iframeRef}
                src={initSrc}
                title='카사요'
                onLoad={handleIframeLoad}
                className='rounded-[18.1px] shadow-[0px_0px_0px_13.6px_#0000001A] bg-[#FFFFFF] aspect-[340/736] h-[736px] z-[1]'
              ></iframe>
            </div>
          </div>
        ) : (
          <InsuranceProductList
            insuranceProducts={insuranceProducts}
            insuranceTypeTab={insuranceTypeTab}
            setInsuranceTypeTab={setInsuranceTypeTab}
          />
        )}

        {activeTab !== 'insurance' && (
          <div className='absolute w-full h-full bg-[#EDF2FD]'></div>
        )}
      </div>
    </div>
  );
}

function MobileServiceUsage({
  insuranceTypeTab,
  setInsuranceTypeTab,
}: {
  insuranceTypeTab: InsuranceTypeTab;
  setInsuranceTypeTab: (insuranceTypeTab: InsuranceTypeTab) => void;
}) {
  return (
    <>
      <div className='w-dvw'>
        {/* 카사요 앱 미리보기 영역 */}
        <div className='relative h-[500px] bg-[#EDF2FD]'>
          <div
            style={{
              backgroundImage: 'url("/imgs/png/service/m_cloud_1.png")',
            }}
            className='absolute bottom-0 right-0 w-full h-full bg-[#EDF2FD] bg-no-repeat bg-cover bg-bottom'
          ></div>
          <img
            src={'/imgs/png/service/m_hand_1.png'}
            alt='손_1'
            className='right-0 top-0 absolute object-cover transition-all max-h-[120px]'
          />
          <img
            src={'/imgs/png/service/m_hand_2.png'}
            alt='손_2'
            className='right-0 bottom-0 absolute object-cover transition-all max-h-[230px]'
          />
          <img
            src={'/imgs/png/service/m_hand_3.png'}
            alt='손_3'
            className='left-0 bottom-0 absolute object-cover transition-all max-h-[135px]'
          />
          <div className='absolute bottom-0 w-full flex items-center justify-center'>
            <img
              src={'/imgs/png/service/m_app_preview_1.png'}
              alt='앱 미리보기'
              className='object-cover w-[261px]'
            />
          </div>
        </div>
        {/* 서비스 바로 이용하기 */}
        <div className='bg-white p-5 pb-[50px]'>
          <button
            onClick={() => {
              window.open('https://app.carsayo.net');
            }}
            className='rounded-[10px] bg-[#222222] w-full p-[22px]'
          >
            <span className='text-white font-semibold text-[16px] leading-[16px] tracking-[-0.4px]'>
              서비스 바로 이용하기
            </span>
          </button>
        </div>
      </div>
      <NoticePopup />
    </>
  );
}

export default function ServiceUsage() {
  const [searchParams] = useSearchParams();
  const initTab = searchParams.get('tab');

  const [insuranceTypeTab, setInsuranceTypeTab] = useState<InsuranceTypeTab>(
    initTab === 'business' ? 'business' : 'personal'
  );

  return (
    <>
      {isBrowser && (
        <BrowserServiceUsage
          insuranceTypeTab={insuranceTypeTab}
          setInsuranceTypeTab={setInsuranceTypeTab}
        />
      )}
      {isMobile && (
        <MobileServiceUsage
          insuranceTypeTab={insuranceTypeTab}
          setInsuranceTypeTab={setInsuranceTypeTab}
        />
      )}
    </>
  );
}
