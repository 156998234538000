import { motion } from 'framer-motion';
import { Button_공정거래위원회, Button_한국소비자원 } from './button';

function BottomChevron() {
  return (
    <svg
      width='76'
      height='76'
      viewBox='0 0 76 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_f_75_16459)'>
        <circle cx='38' cy='38' r='18' fill='#E1E5E9' />
      </g>
      <path
        d='M44 35.8L38 41.8L32 35.8'
        stroke='#222222'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <filter
          id='filter0_f_75_16459'
          x='0'
          y='0'
          width='76'
          height='76'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='10'
            result='effect1_foregroundBlur_75_16459'
          />
        </filter>
      </defs>
    </svg>
  );
}

/** 소비자 중심 경영(CCM) */
function Section01() {
  return (
    <div className='w-[1200px] space-y-4'>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { delay: 0, duration: 0.5 },
        }}
        className='pb-2 text-[#222222] text-[32px] font-bold leading-[48px] tracking-[-0.8px]'
      >
        소비자 중심 경영(CCM)
      </motion.div>

      <div className='flex justify-between items-center'>
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[#222222] text-[18px] font-normal leading-[28.8px] tracking-[-0.45px]'
        >
          기업이 수행하는 모든 활동을 소비자 관점에서 소비자 중심으로 구성하고
          <br />
          관련 경영 활동을 지속적으로 개선하고 있는지를 한국소비자보호원이
          평가하고
          <br />
          공정거래 위원회가 인증하는 제도입니다.
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.15, duration: 0.5 },
          }}
          className='flex gap-2.5'
        >
          <Button_공정거래위원회 className='min-w-[170px]' />
          <Button_한국소비자원 className='min-w-[170px]' />
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0.1, duration: 0.7 },
        }}
        className='w-[1200px]'
      >
        <img
          width={954}
          height={390}
          src='/imgs/png/ccm/description_1.png'
          className='mx-auto mt-14'
        />
      </motion.div>
    </div>
  );
}

/** 설명 표 */
function Section02() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { delay: 0.05, duration: 0.5 },
      }}
      className='w-[1200px] space-y-4'
    >
      <div className='flex border-y border-[#EEE] divide-x divide-[#EEE]'>
        {/* 좌측 영역 */}
        <div className='flex flex-col w-[260px] text-[#222] text-[20px] font-semibold leading-[24px] tracking-[-0.6px] divide-y divide-[#EEE]'>
          <div className='relative flex items-center justify-center h-[140px]'>
            중점 사업
            <div className='absolute bottom-[-38px] z-[1]'>
              <BottomChevron />
            </div>
          </div>
          <div className='relative flex items-center justify-center h-[140px]'>
            핵심 가치
            <div className='absolute bottom-[-38px] z-[1]'>
              <BottomChevron />
            </div>
          </div>
          <div className='relative flex items-center justify-center h-[140px]'>
            비전
          </div>
          <div className='relative flex items-center justify-center h-[400px] text-white bg-[#222]'>
            기업 전략
          </div>
        </div>

        {/* 우측 영역 */}
        <div className='flex-auto divide-y divide-[#EEE]'>
          <div className='h-[140px] grid grid-cols-4 p-4 gap-2.5'>
            <div className='flex bg-[#F8F9FA] items-center justify-center text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
              자동차 쇼핑 통합 플랫폼
            </div>
            <div className='flex bg-[#F8F9FA] items-center justify-center text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
              혁신적인 R&D
            </div>
            <div className='flex bg-[#F8F9FA] items-center justify-center text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
              소비자 친화적 조직 문화
            </div>
            <div className='flex bg-[#F8F9FA] items-center justify-center text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
              자동차 금융 서비스
            </div>
          </div>
          <div className='h-[140px] grid grid-cols-3 p-4 gap-2.5'>
            <div className='flex bg-[#F8F9FA] items-center justify-center text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
              소비자 중심
            </div>
            <div className='flex bg-[#F8F9FA] items-center justify-center text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
              투명성
            </div>
            <div className='flex bg-[#F8F9FA] items-center justify-center text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
              혁신
            </div>
          </div>
          <div className='h-[140px] flex items-center justify-center text-center text-[#222] text-[16px] font-normal leading-[160%] tracking-[-0.4px]'>
            구매 과정의 디지털화, 정보의 투명화, 합리적인 가격구조를 통해 모든
            소비자가 신차 구매, 내 차 팔기, 자동차 금융, 다이렉트 자동차 보험 등
            <br />
            자동차 관련 모든 서비스를 이용 시, 편안함과 만족감을 주는 세상을
            만들겠습니다.
          </div>
          <div
            style={{
              backgroundImage: 'url("/imgs/png/ccm/strategy_flower_x3.png")',
              backgroundSize: '496px, 285px',
            }}
            className='relative flex p-10 items-start justify-center h-[400px] text-white bg-[#222] text-[20px] font-normal leading-[160%] tracking-[-0.5px] bg-bottom bg-no-repeat'
          >
            투명하고 체계적인 판매 및 매입 시스템으로&nbsp;
            <span className='font-semibold'>
              자동차 시장의 깨끗한 유통 거래를 선도
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default function BrowserCCMPage() {
  return (
    <div className='flex flex-col items-center justify-center py-[60px] space-y-20 bg-cover'>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 1 },
        }}
        style={{
          backgroundImage: 'url("/imgs/png/ccm/top_banner_1.png")',
        }}
        className='relative w-[1200px] h-[460px] flex justify-between items-start px-20 pr-5 py-[80px] bg-cover'
      >
        <div className='flex flex-col gap-6'>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0, duration: 0.5 },
            }}
            className='text-white text-[40px] font-bold leading-[150%] tracking-[-1px]'
          >
            자동차 플랫폼 업계 최초로
            <br />
            소비자중심경영(CCM) 인증 획득
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.2, duration: 0.5 },
            }}
            className='text-white text-[18px] font-normal leading-[160%] tracking-[-0.45px]'
          >
            고객의 소리에 귀를 기울이고, 고객과의 진정성 있는 소통을 하고
            있습니다.
            <br />
            고객의 효율증대와 권익증진을 목표로 삼고 상생가치를 추구하며 <br />
            "고객에게 신뢰받는 기업"이 되겠습니다.
          </motion.div>

          <button
            onClick={() => {
              window.open('https://www.joongang.co.kr/article/25298873/');
            }}
            className='w-fit flex items-center gap-4 mt-0.5 py-3.5 pl-6 pr-4 rounded-[32px] bg-white text-[#222] text-[18px] font-semibold leading-[18px] tracking-[-0.45px]'
          >
            보도 자료 바로가기
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <g clipPath='url(#clip0_244_9733)'>
                <path
                  d='M9 6L15 12L9 18'
                  stroke='#222'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_244_9733'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>

        <div
          onClick={() => {
            window.open('https://www.joongang.co.kr/article/25298873');
          }}
          className='absolute right-[246px] bottom-[70px] cursor-pointer shadow-[0px_6.25px_18.75px_#00000026] rounded-[4px]'
        >
          <img
            src='../imgs/png/company/history/ccm_img.png'
            alt='인증서 이미지'
            className='w-[240px] h-[320px] shadow-[0px_0px_0px_2px_#00000066] rounded-[4px]'
          />
        </div>
        {/* <div
          onClick={() => {
            window.open('https://www.joongang.co.kr/article/25298873');
          }}
          className='absolute right-[246px] bottom-[70px] cursor-pointer'
        >
          <img
            width={240}
            height={320}
            src='../imgs/png/company/history/ccm_img.png'
          ></img>
        </div> */}
      </motion.div>

      <Section01 />
      <Section02 />
    </div>
  );
}
