import { motion } from 'framer-motion';
import { cn } from 'util/index';
import { ccmTags, list } from '../main';
import { SButton_공정거래위원회, SButton_한국소비자원 } from 'pages/ccm/button';
import { Plus } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Section02() {
  return (
    <section className='w-[1200px] pt-[100px] pb-[40px]'>
      <div className='w-full h-full flex flex-col justify-start items-start gap-6'>
        <motion.h3
          initial={{ opacity: 0, x: -20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[32px] leading-[48px] font-[700]'
        >
          카사요는 왜 다른가요?
        </motion.h3>

        <div className='w-full'>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0.1, duration: 1 },
            }}
            className='w-full bg-[#F8F9FA] border-b border-[#EEEEEE] flex'
          >
            <div className='flex-1 flex flex-col gap-[10px]'>
              <div className='py-[13px] w-full border-b border-[#EEEEEE] px-[30px] flex justify-between items-center'>
                <div className='font-medium text-[14px] leading-[14px]'>
                  소비자가 신뢰하는 기업
                </div>
                <ul className='bg-white rounded-[10px] px-[10px] py-[10.5px] flex gap-[4px] font-medium text-[13px] leading-[13px] text-[#555555]'>
                  {ccmTags.map((item, index) => (
                    <li
                      key={`ccmTag_${index}`}
                      className={cn('flex gap-[4px]', item.style)}
                    >
                      {index > 0 && (
                        <Plus
                          width={14}
                          height={14}
                          color='#999999'
                          strokeWidth={1.17}
                        />
                      )}
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
              <Link to={'/ccm'}>
                {' '}
                <div className='relative p-[30px]'>
                  <div className='font-semibold text-[18px] leading-[27px] bg-gradient-to-r from-[#21AEFF] to-[#6465FF] bg-clip-text mb-[4px] text-transparent max-w-[210px]'>
                    자동차 플랫폼 업계 최초&유일
                  </div>

                  <div className='font-semibold text-[24px] leading-[36px] mb-4'>
                    국가공인제도 소비자 중심경영(CCM) 인증
                  </div>
                  <div className='font-normal text-[16px] leading-[25.6px] mb-[26px]'>
                    모든 경영 활동 중심에 고객을 두고 고객의 요구와 기대를
                    최우선으로 고려하여
                    <br />
                    제품과 서비스를 제공하는 경영 철학과 실천을 공식적으로
                    인정하는 국가공인제도입니다.
                    <br />
                    카사요(주)는 고객이 신뢰할 수 있는 기업임을 공식적으로
                    입증했습니다.
                  </div>

                  <div className='bg-[#EEF0F2] py-4 px-[17px] rounded-[10px] font-semibold text-[15px] leading-[24px] text-center'>
                    업계 단독 “자동차보험 중개 플랫폼(웹/앱)” 특허 보유 -{' '}
                    <span className='font-bold text-[15px] leading-[24px] text-[#0079FC]'>
                      특허 제 10-2761390 호
                    </span>
                  </div>

                  <div className='flex flex-col gap-2 absolute right-[30px] top-[30px]'>
                    <SButton_공정거래위원회 className='bg-white border-[#EEEEEE] border' />
                    <SButton_한국소비자원 className='bg-white border-[#EEEEEE] border w-[127px] h-[80px] gap-[18px]' />
                  </div>
                </div>
              </Link>
            </div>
            <div className='flex bg-[#EEF0F2] px-[97px] flex-col items-center justify-center m-w-[400px]'>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  window.open('https://www.joongang.co.kr/article/25298873');
                }}
                className='cursor-pointer'
              >
                <img
                  src='../imgs/png/company/history/ccm_img.png'
                  alt='인증서 이미지'
                  className='w-[206px] h-[275px] rounded-[8px] border border-[#00000066]'
                  style={{ boxShadow: '0px 6.25px 18.75px 0px #00000026' }}
                />
              </div>
            </div>
          </motion.div>
          <motion.ul
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0.1, duration: 1 },
            }}
            className='w-full grid grid-cols-3 bg-[#F8F9FA]'
          >
            {list.map((item, index) => (
              <li
                key={`section02List_${item.title}`}
                className={cn(
                  'p-[30px] min-h-[260px] flex flex-col justify-start items-start relative gap-4 border-[#EEEEEE] border-r',
                  (index === 2 || index === 5) && 'border-r-0',
                  index > 2 && 'border-t'
                )}
              >
                <span className='mb-1 min-w-[46px] text-[15px] leading-[15px] font-[700] inline-flex justify-center items-center py-[6px] px-[11px] bg-[#222] rounded-[100px] text-white'>
                  0{index + 1}
                </span>
                <div>
                  <strong className='inline-block text-[20px] leading-[30px] text-[#000] font-[600]'>
                    {item.title}
                  </strong>
                  {item.point && (
                    <span className='mt-[5.5px] flex justify-start items-center pl-2 text-[#000] border-[#222] border-l-[3px] text-[16px] leading-[19px] font-[500]'>
                      {item.point}
                    </span>
                  )}
                </div>

                <p className='whitespace-pre-line'>{item.description}</p>
                <div className='flex flex-col justify-center items-center gap-[12px] absolute bottom-[25px] right-[26px]'>
                  <img
                    className='h-[58px] object-contain'
                    src={item.imgUrl}
                    alt={`${item.title}_이미지`}
                  />
                  <img
                    src='../imgs/png/main_section02_shadow.png'
                    alt='그림자'
                    className='w-[60px] object-contain'
                  />
                </div>
              </li>
            ))}
          </motion.ul>
        </div>
      </div>
    </section>
  );
}
