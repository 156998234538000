import { LinkUrl } from 'common/data/links';
import AppleIcon from 'common/icons/AppleIcon';
import GoogleIcon from 'common/icons/GoogleIcon';
import CarsayoLogo from 'common/icons/logo';
import { isBrowser, isMobile } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';

function BrowserFooter() {
  const location = useLocation();
  return (
    <footer className='w-full min-w-[1260px] bg-[#F8F9FA] border-t border-[#EEEEEE] flex-none'>
      <div className='w-full flex flex-col justify-center items-center'>
        <div className='w-full border-b border-[#EEEEEE]'>
          <div className='w-[1200px] h-[70px] mx-auto py-[20px] flex justify-between items-center'>
            {location.pathname === '/' ? (
              <h1
                className='w-[120px] shrink-0 cursor-pointer'
                onClick={() => {
                  window.location.reload();
                  window.scrollTo({ top: 0 });
                }}
              >
                <CarsayoLogo width='120' />
              </h1>
            ) : (
              <Link to='/' className='w-[120px] shrink-0'>
                <CarsayoLogo width='120' />
              </Link>
            )}

            <div className='flex h-[70px] gap-3.5'>
              <div className='flex justify-center items-center gap-4'>
                <Link
                  to={LinkUrl.벤처확인기업}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='w-[105px] h-[30px] flex justify-center items-center mr-[3px]'
                >
                  <img
                    src='/imgs/png/venture_mark.png'
                    alt='벤처확인기업마크'
                    className='w-full object-contain'
                  />
                </Link>
                <Link
                  to={LinkUrl.지식재산경영인증}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='w-[153px] h-[43px] flex justify-center items-center'
                >
                  <img
                    src='/imgs/png/cert_mark.png'
                    alt='지식재산경영인증마크'
                    className='w-full object-contain'
                  />
                </Link>
                <Link
                  to={LinkUrl.소비자중심경영소개}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='w-[49px] h-[49px] flex justify-center items-center'
                >
                  <img
                    src='/imgs/png/ccm_mark.png'
                    alt='소비자중심경영인증마크'
                    className='w-full object-contain'
                  />
                </Link>
                <Link
                  to={LinkUrl.가족친화지원사업}
                  target='_blank'
                  className='w-[49px] h-[49px] flex justify-center items-center'
                >
                  <img
                    src='/imgs/png/family_friendly_mark.png'
                    alt='가족친화인증마크'
                    className='w-full object-contain'
                  />
                </Link>
              </div>

              <div className='w-[1px] bg-[#EEE]'></div>

              <div className='flex justify-center items-center gap-2.5'>
                <Link
                  to={LinkUrl.앱.앱스토어}
                  target='_blank'
                  className='bg-[#fff] flex w-[116px] h-[40px] justify-center items-center gap-1 border border-[#E5E5EC] rounded-[6px] hover:bg-gray-50/10 transition-all'
                >
                  <AppleIcon fill='#111111' />
                  <span className='text-[14px] leading-[14px]'>App Store</span>
                </Link>
                <Link
                  to={LinkUrl.앱.플레이스토어}
                  target='_blank'
                  className='bg-[#fff] flex w-[116px] h-[40px] justify-center items-center gap-1 border border-[#E5E5EC] rounded-[6px] hover:bg-gray-50/10 transition-all'
                >
                  <GoogleIcon />
                  <span className='text-[14px] leading-[14px]'>Play Store</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full border-b border-[#EEEEEE] py-[13px]'>
          <div className='w-[1200px] mx-auto flex justify-between items-center'>
            <div className='flex flex-col justify-start items-start gap-[14px] text-[13px] leading-[13px] text-[#555555]'>
              <p className='flex justify-center items-center gap-2.5 divide-x-[1px] border-[#CCCCCC]'>
                <span>대표이사 : 최용민</span>
                <span className='pl-[10px]'>
                  주소 : (04790) 서울특별시 성동구 아차산로 49, 1403호
                </span>
              </p>
              <p>
                <span>벤처기업 20240514030044 (혁신성장형)</span>
              </p>
              <p className='flex justify-center items-center gap-2.5 divide-x-[1px] border-[#CCCCCC]'>
                <span>사업자등록번호 : 142-87-01847</span>
                <span className='pl-[10px]'>
                  통신판매업신고 : 제 2021-서울성동-00533
                </span>
              </p>
              <p>
                <span>제휴 · 문의 메일 : info@carsayo.net</span>
              </p>
            </div>
            <div className='bg-[#F2F4F6] rounded-[6px] p-4 w-[246px]'>
              <strong className='inline-block text-[18px] leading-[18px] font-[600] mb-[15px]'>
                1668-1571
              </strong>
              <p className='flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px] mb-[12px]'>
                <span>평일</span>
                <span className='font-[500]'>10:00~18:00</span>
              </p>
              <p className='flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px] mb-[12px]'>
                <span>점심시간</span>
                <span className='font-[500]'>13:00~14:00</span>
              </p>
              <p className='flex justify-between items-center text-[13px] leading-[13px]'>
                <span>휴무</span>
                <span className='font-[500]'>주말 및 공휴일</span>
              </p>
            </div>
          </div>
        </div>
        <div className='w-[1200px] mx-auto py-[21px] flex justify-start items-center'>
          <p className='text-[13px] leading-[18px] text-[#555]'>
            Copyright ⓒ 카사요(주) ALL RIGHTS RESERVED.
          </p>
        </div>
      </div>
    </footer>
  );
}

function MobileFooter() {
  return (
    <footer className='w-full bg-[#F8F9FA] border-t border-[#EEEEEE] flex-none'>
      <div className='h-[70px] flex justify-between items-center px-5 border-b border-[#EEEEEE]'>
        <Link to='/' className='shrink-0'>
          <CarsayoLogo width='120' />
        </Link>
      </div>
      <div className='px-5 py-6 space-y-6'>
        {/* 텍스트 영역 */}
        <div className='space-y-[14px] text-[#555] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
          <div>대표이사 : 최용민</div>
          <div>주소 : (04790) 서울특별시 성동구 아차산로 49, 1403호</div>
          <div>벤처기업 20240514030044 (혁신성장형)</div>
          <div>사업자등록번호 : 142-87-01847</div>
          <div>통신판매업신고 : 제 2021-서울성동-00533</div>
          <div>제휴 · 문의 메일 : info@carsayo.net</div>
        </div>

        {/* 전화번호 및 앱 배너 */}
        <div className='flex justify-between gap-4'>
          <div className='min-w-[180px] bg-[#F2F4F6] rounded-[6px] p-3.5 px-4 space-y-3'>
            <strong className='mb-0.5 text-[#222] font-semibold text-[15px] leading-[15px] tracking-[-0.375px]'>
              1668-1571
            </strong>
            <p className='flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
              <span>평일</span>
              <span className='font-[500]'>10:00~18:00</span>
            </p>
            <p className='flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
              <span>점심시간</span>
              <span className='font-[500]'>13:00~14:00</span>
            </p>
            <p className='flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
              <span>휴무</span>
              <span className='font-[500]'>주말 및 공휴일</span>
            </p>
          </div>

          <div className='w-full grid grid-rows-2 gap-4'>
            <Link
              to={LinkUrl.앱.앱스토어}
              target='_blank'
              className='flex justify-center items-center gap-1 rounded-[6px] border border-[var(--Line-Regular_Color,#E5E5EC)] bg-[#FFF] px-4'
            >
              <AppleIcon fill='#111111' />
              <div className='text-[14px] font-normal leading-[14px] tracking-[-0.35px] tiny:hidden'>
                App Store
              </div>
            </Link>

            <Link
              to={LinkUrl.앱.플레이스토어}
              target='_blank'
              className='flex justify-center items-center gap-1 rounded-[6px] border border-[var(--Line-Regular_Color,#E5E5EC)] bg-[#FFF] px-4'
            >
              <GoogleIcon />
              <div className='text-[14px] font-normal leading-[14px] tracking-[-0.35px] tiny:hidden'>
                Play Store
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className='h-[60px] flex items-center px-5 border-t border-[#EEEEEE] text-[#555] text-[13px] font-normal leading-[18px] tracking-[-0.325px]'>
        Copyright ⓒ 카사요(주) ALL RIGHTS RESERVED.
      </div>
      <div className='h-[70px] w-full flex justify-end items-center border-t border-[#EEEEEE] px-[14px]'>
        <div className='flex justify-center items-center gap-[6px] py-[14px]'>
          <Link
            to={LinkUrl.벤처확인기업}
            target='_blank'
            rel='noopener noreferrer'
            className='w-[70px] h-[20px] flex justify-center items-center mr-[3px]'
          >
            <img
              src='/imgs/png/venture_mark.png'
              alt='벤처확인기업마크'
              className='w-full object-contain'
            />
          </Link>
          <Link
            to={LinkUrl.지식재산경영인증}
            target='_blank'
            rel='noopener noreferrer'
            className='w-[102px] h-[20px] flex justify-center items-center mr-[3px]'
          >
            <img
              src='/imgs/png/cert_mark.png'
              alt='지식재산경영인증마크'
              className='w-full object-contain'
            />
          </Link>
          <Link
            to={LinkUrl.소비자중심경영소개}
            target='_blank'
            rel='noopener noreferrer'
            className='w-[42px] h-[42px] flex justify-center items-center'
          >
            <img
              src='/imgs/png/ccm_mark.png'
              alt='소비자중심경영인증마크'
              className='w-full object-contain'
            />
          </Link>
          <Link
            to={LinkUrl.가족친화지원사업}
            target='_blank'
            className='w-[42px] h-[42px] flex justify-center items-center'
          >
            <img
              src='/imgs/png/family_friendly_mark.png'
              alt='가족친화인증마크'
              className='w-full object-contain'
            />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default function CarsayoFooter() {
  return (
    <>
      {isBrowser && <BrowserFooter />}
      {isMobile && <MobileFooter />}
    </>
  );
}
